

<template>
  <v-container
    id="reset-password"
    class="fill-height justify-center"
    tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>
    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center" v-if="token">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          :width="(pwdHint)? 900 : 400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Set a new password
              </h1>
            </div>
          </template>
          <v-row>
            <v-col :cols="(pwdHint) ? 6 : 12">
              <form @submit="checkForm" novalidate="true">
                <v-card-text class="text-center">

                  <v-text-field
                    type="password"
                    class="mb-8"
                    color="secondary"
                    label="New password..."
                    prepend-icon="mdi-lock-outline"
                    v-model="password"
                  />
                  <v-text-field
                    type="password"
                    class="mb-8"
                    color="secondary"
                    label="Confirm password..."
                    prepend-icon="mdi-lock-outline"
                    v-model="confirmation"
                  />

                  <pages-btn
                    large
                    color=""
                    depressed
                    class="v-btn--text info--text"
                    @click="checkForm"
                  >
                    <v-icon class="mr-1">mdi-lock</v-icon>
                    Reset password
                  </pages-btn>
                </v-card-text>
              </form>
            </v-col>
            <v-col cols="6" v-if="pwdHint">
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one digit.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one lowercase letter.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one uppercase letter.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password requires at least one special character.
              </v-alert>
              <v-alert
                  class="pa-1 pl-5"
                  type="info"
                  outlined
                  border="left">
                The Password should be between 10 and 32 characters.
              </v-alert>
            </v-col>
          </v-row>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
    <v-row justify="center" v-else>
      <v-slide-y-transition appear>
        <base-material-card
          color="error"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Password reset
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            The password reset token is invalid.
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>

import globalMixins from "../../mixins/globalMixins";

export default {
  name: 'PagesResetPassword',

  mixins: [globalMixins],

  props: {
    token: {
      type: String
    }
  },

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
    PagesBtn: () => import('./components/Btn')
  },

  data: () => ({
    password: '',
    confirmation: '',
    pwdHint: false
  }),

  created() {
    this.redirectIfAuthenticated();
  },

  methods: {
    checkForm: function (e) {
      this.pwdHint = false;
      if (!this.validPassword(this.password)) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'The password should be at least 10 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
        });
        this.pwdHint = true;
        return false;
      }

      if (this.password !== this.confirmation) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'The provided password and confirmation don\'t match.'
        });
        return false;
      }

      this.submit();
      e.preventDefault();
    },

    submit() {
      const self = this;
      self.getSendData({
        'url': '/user/password/reset',
        'method': 'post',
        'headers': {
          'env': 'portal'
        },
        'params': {
          'token': self.token,
          'password': self.password,
          'confirmation': self.confirmation,
        }
      }).then(() => {
        setTimeout(() => {
          this.pushMessage({
            type: 'success',
            title: 'Password Reset',
            text: 'You have successfully reset your password, please login.'
          });
        }, 1000);
        this.$router.push({ name: 'Login' });
      }).catch(() => {
        // already handled in getSendData
      });
    }
  },
}
</script>
